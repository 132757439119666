import { useCallback, useState } from 'react'
import { useParams } from 'react-router'
import { useRecoilValue } from 'recoil'
import { usePost } from '~brix/connectors/fetch'
import { exconEventId } from '../state/exconEventId'
import { tokenParticipantId } from '../state/tokenParticipantId'
import { PlatformFormData } from '../Unauthorized'

export const useRequestSecureLink = () => {
  const { exconId } = useParams<{ exconId: string }>()
  const participantId = useRecoilValue(tokenParticipantId)
  const eventId = useRecoilValue(exconEventId)

  const [errorOcurred, setErrorOcurred] = useState(false)
  const [requested, setRequested] = useState(false)

  const { post } = usePost({ onError: () => setErrorOcurred(true) })

  const requestLink = useCallback(
    async (platforms: PlatformFormData) => {
      setRequested(true)
      await post(`/excon/${exconId}/requestToken`, { participantId, platforms, eventId })
    },
    [exconId, post, participantId, eventId],
  )

  return {
    requestLink,
    requested,
    errorOcurred,
  }
}

import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Breakpoint, Container, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ClassNames from 'classnames'
import { Flex, FlexProps } from 'mui-blox'
import TitleBar from './topbar/TitleBar'
import { useBreakpoint, useBreakpoints, useSetExconTitle } from '~layout'
import BreadcrumbButtons from '../_controls/navigation/BreadcrumbButtons'
import { useSetPageTitle } from './hooks/useSetPageTitle'
import { useHeaderContext } from '../exCon/common/hooks/HeaderContext'

interface CenterPageProps extends FlexProps {
  pageTitle?: string
  logoUrl?: string
  allowBack?: boolean
  hideTitleBar?: boolean
}

const CenteredPage = ({
  className,
  pageTitle = '',
  logoUrl,
  allowBack = false,
  hideTitleBar = false,
  children,
}: PropsWithChildren<CenterPageProps>) => {
  const { claimHeader, releaseHeader } = useHeaderContext()
  const [canRender, setCanRender] = useState(false)
  const priority = 2

  const classes = useStyles()
  const isSm = useBreakpoint('md').down
  const { current } = useBreakpoints()

  const { pageTitle: contextPageTitle } = useSetPageTitle()
  const resolvedPageTitle = pageTitle || contextPageTitle

  const { setFallbackTitle } = useSetExconTitle()

  useEffect(() => {
    if (hideTitleBar) {
      return
    }
    const claimed = claimHeader(priority)

    setCanRender(claimed)

    if (!claimed && resolvedPageTitle.trim()) {
      setFallbackTitle(resolvedPageTitle)
    }

    return () => releaseHeader(priority)
  }, [hideTitleBar, canRender, resolvedPageTitle, claimHeader, releaseHeader, setFallbackTitle])

  return (
    <Flex flexColumn className={ClassNames(classes.root, className)} sx={{ backgroundColor: 'background.default' }}>
      {!hideTitleBar && canRender && (
        <TitleBar pageTitle={resolvedPageTitle} logoUrl={logoUrl} allowBack={allowBack} />
      )}
      <Container
        role='main'
        maxWidth={current as Breakpoint}
        disableGutters={isSm}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          overflow: 'hidden',
          backgroundColor: 'background.default',
        }}
      >
        {children}
      </Container>
      <BreadcrumbButtons allowBack={allowBack} className={classes.navButtons} customGoBackHandler={undefined} />
    </Flex>
  )
}

const useStyles = makeStyles<Theme & { layout: { topBarMedium: number } }>(
  ({ shadows, spacing, zIndex, layout }) => ({
    root: {
      position: 'relative',
    },
    navButtons: () => ({
      height: '22px',
      position: 'absolute',
      top: `${layout.topBarMedium - 11}px`,
      left: spacing(1.5),
      zIndex: zIndex.appBar + 1,
      boxShadow: shadows[3],
    }),
  }),
)

export default CenteredPage

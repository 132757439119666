import React, { createContext, useContext, useRef } from 'react'

interface HeaderContextProps {
  claimHeader: (priority: number) => boolean
  releaseHeader: (priority: number) => void
  currentPriority: React.MutableRefObject<number | null>
}

const HeaderContext = createContext<HeaderContextProps | undefined>(undefined)

export const HeaderProvider = ({ children }: React.PropsWithChildren<Record<string, never>>) => {
  const currentPriority = useRef<number | null>(null)

  const claimHeader = (priority: number): boolean => {
    if (currentPriority.current === null || priority <= currentPriority.current) {
      currentPriority.current = priority

      return true
    }

    return false
  }

  const releaseHeader = (priority: number): void => {
    if (currentPriority.current === priority) {
      currentPriority.current = null
    }
  }

  return (
    <HeaderContext.Provider value={{ claimHeader, releaseHeader, currentPriority }}>
      {children}
    </HeaderContext.Provider>
  )
}

export const useHeaderContext = (): HeaderContextProps => {
  const context = useContext(HeaderContext)

  if (!context) {
    throw new Error('useHeaderContext must be used within a HeaderProvider')
  }

  return context
}

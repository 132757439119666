import { fromJS, List } from 'immutable'
import React, { useMemo, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { POWER_UP_KEYS } from '~constants'
import { ImmutableMap } from '~core/botPlan/executors/types'
import TitleBar from '../topbar/TitleBar'
import { useSetExconTitle } from '~layout'
import BreadcrumbButtons from '../../_controls/navigation/BreadcrumbButtons'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { useHeaderContext } from '../../exCon/common/hooks/HeaderContext'

export type BotMenuSettings = { homePageEnabled: boolean; availableExcons: ImmutableMap<Record<string, unknown>>[] }
interface TopBarProps {
  logoUrl?: string
  pageTitle?: string
  settings: ImmutableMap<{
    branding: Record<string, unknown>
    powerUpSettings: { botMenu?: BotMenuSettings }
    hostname?: string
    eventId: string
  }>
}

const ExconTopBar = ({ logoUrl, pageTitle = '', settings }: TopBarProps): JSX.Element | null => {
  const { claimHeader, releaseHeader } = useHeaderContext()
  const [canRender, setCanRender] = useState(false)
  const priority = 1

  const botMenuSettings: ImmutableMap<BotMenuSettings> = settings.getIn(
    ['powerUpSettings', POWER_UP_KEYS.botMenu],
    fromJS({}),
  ) as ImmutableMap<BotMenuSettings>

  const { pathname } = useLocation()
  const { hideTitleBar, pageTitle: contextPageTitle, showBackButton } = useSetExconTitle()

  const shellEnabled = useMemo(() => {
    const homePageEnabled = botMenuSettings.get('homePageEnabled', false)
    const currentExcon = botMenuSettings
      .get('availableExcons', List())
      .find((excon: ImmutableMap<{ exconId: string }>) => {
        return pathname.indexOf(excon.get('exconId', '')) > 0
      })

    return homePageEnabled && !!currentExcon
  }, [botMenuSettings, pathname])

  const resolvedPageTitle = pageTitle || contextPageTitle

  const hideTitle = shellEnabled || hideTitleBar || false

  const classes = useStyles({ hideTitle })

  useEffect(() => {
    const claimed = claimHeader(priority)

    setCanRender(claimed)

    return () => {
      releaseHeader(priority)
    }
  }, [claimHeader, releaseHeader])

  if (!canRender) return null

  return (
    <>
      {hideTitle && showBackButton && <div style={{ height: 46 }} />}
      {!hideTitle && <TitleBar pageTitle={resolvedPageTitle} logoUrl={logoUrl} allowBack={showBackButton} />}
      <BreadcrumbButtons allowBack={showBackButton} className={classes.navButtons} customGoBackHandler={undefined} />
    </>
  )
}

const useStyles = makeStyles<Theme & { layout: { topBarMedium: number } }, { hideTitle: boolean }>(
  ({ shadows, zIndex, layout }) => ({
    navButtons: ({ hideTitle }) => ({
      height: '22px',
      position: 'absolute',
      top: hideTitle ? '12px' : `${layout.topBarMedium - 11}px`,
      left: '12px',
      zIndex: zIndex.appBar + 1,
      boxShadow: shadows[3],
    }),
  }),
)

export default ExconTopBar

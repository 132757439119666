import React, { useState, ReactNode } from 'react'
import { ExconTitleContext } from '../hooks/useSetExconTitle'

interface ExconTitleProviderProps {
  children: ReactNode
}

const ExconTitleProvider = ({ children }: ExconTitleProviderProps) => {
  const [pageTitle, _setPageTitle] = useState<string>('')
  const [pageSubtitle, _setPageSubtitle] = useState<string>('')
  const [windowTitle, _setWindowTitle] = useState<string>('')
  const [fallbackTitle, _setFallbackTitle] = useState<string>('')
  const [tools, _setTools] = useState<Element | null>(null)
  const [showBackButton, _setShowBackButton] = useState<boolean>(false)
  const [hideTitleBar, _setHideTitleBar] = useState<boolean>(false)

  const setPageTitle = (title: string, subTitle: string, windowTitle: string, enableBackButton: boolean) => {
    _setPageTitle(title)
    _setPageSubtitle(subTitle)
    _setWindowTitle(windowTitle)
    _setShowBackButton(enableBackButton)
  }

  const setFallbackTitle = (title: string): void => {
    _setFallbackTitle(title)
  }

  const setTools = (tools: Element | null): void => {
    _setTools(tools)
  }

  const setHideTitleBar = (hide: boolean): void => {
    _setHideTitleBar(hide)
  }

  const setShowBackButton = (show: boolean): void => {
    _setShowBackButton(show)
  }

  return (
    <ExconTitleContext.Provider
      value={{
        pageTitle,
        pageSubtitle,
        windowTitle,
        fallbackTitle,
        setPageTitle,
        setFallbackTitle,
        setTools,
        tools,
        showBackButton,
        hideTitleBar,
        setHideTitleBar,
        setShowBackButton,
      }}
    >
      {children}
    </ExconTitleContext.Provider>
  )
}

export default ExconTitleProvider

import { useParams } from 'react-router'
import useSWR from 'swr'
import { useRecoilValue } from 'recoil'
import { usePost } from '~brix/connectors/fetch'
import { NEW_LINK_DELIVERY_PLATFORMS } from '~constants'
import { tokenParticipantId } from '../state/tokenParticipantId'
import { exconEventId } from '../state/exconEventId'

interface SupportedPlatforms {
  [NEW_LINK_DELIVERY_PLATFORMS.email]: boolean
  [NEW_LINK_DELIVERY_PLATFORMS.sms]: boolean
  [NEW_LINK_DELIVERY_PLATFORMS.web]: boolean
}

export const useRequestTokenPlatforms = () => {
  const { exconId } = useParams<{ exconId: string }>()
  const participantId = useRecoilValue(tokenParticipantId)
  const eventId = useRecoilValue(exconEventId)
  const { post, isPosting } = usePost<SupportedPlatforms>()
  const key = `/excon/${exconId}/requestTokenPlatforms`

  const { data: supportedPlatforms } = useSWR(
    key,
    async url => {
      const result = await post(url, { participantId, eventId })

      return result || {}
    },
    { revalidateOnFocus: false, revalidateIfStale: false },
  )

  return {
    supportedPlatforms,
    isGettingPlatforms: isPosting,
  }
}

import { createContext, useContext } from 'react'

export interface ContextProps {
  pageTitle: string
  pageSubtitle: string
  windowTitle: string
  fallbackTitle: string
  setPageTitle: (...args: any) => void
  setFallbackTitle: (title: string) => void
  setTools?: (...args: any) => void
  tools: Element | null
  showBackButton: boolean
  hideTitleBar: boolean
  setHideTitleBar: (hide: boolean) => void
  setShowBackButton: (show: boolean) => void
}

export const ExconTitleContext = createContext<ContextProps>({
  pageTitle: '',
  pageSubtitle: '',
  windowTitle: '',
  fallbackTitle: '',
  setPageTitle: () => {},
  setFallbackTitle: () => {},
  setTools: () => {},
  tools: null,
  showBackButton: false,
  hideTitleBar: false,
  setHideTitleBar: () => {},
  setShowBackButton: () => {},
})

export const useSetExconTitle = () => useContext(ExconTitleContext)

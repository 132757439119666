import React, { Children, forwardRef, ReactNode, useCallback, useMemo } from 'react'
import { AppBar, Typography, IconButton, Avatar, Stack, Box, useTheme, Toolbar, Collapse } from '@mui/material'
import { COMPANY_TITLE } from '~constants'
import { useSetExconTitle, useTriggeredScroll } from '~layout'
import Title from '../../page/Title'
import { useEventBranding } from '../../../brix/connectors/context/useEventBranding'
import Logo from '../logo/LogoLarge'
import { useSetPageTitle } from '../hooks/useSetPageTitle'
import { iconUsages, SemanticIcon } from '~controls'

function useIsInIframe(): boolean {
  return useMemo(() => typeof window !== 'undefined' && window.parent !== window, [])
}

export interface TitleBarProps {
  children?: ReactNode
  pageTitle?: string
  pageHeader?: string
  logoUrl?: string
  allowBack?: boolean
}

const TitleBar = forwardRef<HTMLDivElement, TitleBarProps>(
  ({ children, pageTitle, pageHeader, logoUrl, allowBack = false }, ref) => {
    const theme = useTheme()

    const [hasScrolled] = useTriggeredScroll()
    const { branding, BRANDING_KEYS } = useEventBranding()

    const { pageSubtitle } = useSetPageTitle()
    const { fallbackTitle } = useSetExconTitle()

    const isInIframe = useIsInIframe()

    const handleBack = useCallback(() => {
      window.parent.postMessage({ type: 'CLOSE_EXCON' }, '*')
    }, [])

    const resolvedTitle = useMemo(() => (pageTitle ? `${pageTitle} - ${COMPANY_TITLE}` : COMPANY_TITLE), [pageTitle])

    const exconTitle = useMemo(() => pageTitle || fallbackTitle, [pageTitle, fallbackTitle])

    const resolvedHeader = useMemo(
      () => branding[BRANDING_KEYS.title] || pageHeader || pageSubtitle,
      [branding, BRANDING_KEYS.title, pageHeader, pageSubtitle],
    )

    const resolvedLogoUrl = useMemo(
      () => branding[BRANDING_KEYS.logoUrl] || logoUrl,
      [branding, BRANDING_KEYS.logoUrl, logoUrl],
    )

    const hasTools = Children.count(children) > 0

    const headerBackgroundColor = theme.palette.primary.main
    const headerContrastText = theme.palette.primary.contrastText

    return (
      <AppBar
        ref={ref}
        position='static'
        elevation={hasScrolled ? 4 : 0}
        sx={{
          width: '100%',
          backgroundColor: headerBackgroundColor,
          color: headerContrastText,
        }}
      >
        <Title render={resolvedTitle} />

        <Toolbar
          disableGutters
          sx={{
            minHeight: 68,
            px: 2,
            py: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Stack direction='row'>
            {isInIframe && (
              <Collapse
                in={!allowBack}
                orientation='horizontal'
                collapsedSize='0px'
                timeout={{ enter: 200, exit: 200 }}
                easing={{
                  enter: theme.transitions.easing.easeOut,
                  exit: theme.transitions.easing.easeInOut,
                }}
                sx={{ my: 'auto', height: '100%', mr: 1.5 }}
              >
                <IconButton
                  onClick={handleBack}
                  sx={{
                    p: 0,
                  }}
                  aria-label='Go Back'
                >
                  <Avatar
                    sx={{
                      backgroundColor: headerContrastText,
                      color: headerBackgroundColor,
                      height: 35,
                      width: 35,
                    }}
                  >
                    <SemanticIcon usage={iconUsages.arrowLeft} />
                  </Avatar>
                </IconButton>
              </Collapse>
            )}
            <Stack
              direction='column'
              spacing={0.5}
              sx={{
                justifyContent: 'space-evenly',
                alignItems: 'flex-start',
                ml: !isInIframe ? 0 : 0.5,
              }}
            >
              <Typography
                sx={{
                  color: headerContrastText,
                  fontSize: theme.mixins.rem(1.625),
                  fontWeight: 700,
                  fontFamily: 'Roboto, Arial, san-serif',
                  lineHeight: 1.1,
                }}
              >
                {exconTitle}
              </Typography>
              {resolvedHeader && (
                <Typography
                  color='inherit'
                  sx={{
                    fontSize: theme.mixins.rem(1),
                    fontWeight: 500,
                    fontFamily: 'Roboto, Arial, san-serif',
                    lineHeight: 1,
                  }}
                >
                  {resolvedHeader}
                </Typography>
              )}
            </Stack>
            {hasTools && (
              <Stack direction='row' alignItems='center' sx={{ height: '100%' }}>
                {children}
              </Stack>
            )}
          </Stack>
          {!isInIframe &&
            (resolvedLogoUrl ? (
              <Box
                component='img'
                src={resolvedLogoUrl}
                alt={resolvedHeader ? resolvedHeader : 'app-logo'}
                sx={{
                  height: 50,
                  width: 'auto',
                  pr: 0.5,
                }}
              />
            ) : (
              <Logo />
            ))}
        </Toolbar>
      </AppBar>
    )
  },
)

TitleBar.displayName = 'TitleBar'

export default TitleBar
